<template>
  <el-container class="w-100 report-list">
    <el-row class="empty" v-if="tableData.length < 1 && !loading">
      <div>
        <img
          src="@/assets/images/common/empty/bjsd.png"
          alt="比较设定"
          width="400"
          class="img"
          type="image/svg+xml"
        />
        <p>
          <span>什么都没有，快来制作比较报告吧</span>
          <el-button type="primary" round @click="createReport"
            >制作报告</el-button
          >
        </p>
      </div>
    </el-row>
    <div v-else class="w-100 min-h-600">
      <div class="mod-list lh-60 average-list" v-loading="loading">
        <ul>
          <li>
            <h3>
              <span class="num-tit">序号</span>
              <span class="tag w-300">简报名称</span>
              <span class="tag">简报类型</span>
              <span class="tag">报告生成时间</span>
              <span class="tag">创建者</span>
              <span class="tag">状态</span>
              <span class="tag">操作</span>
            </h3>
          </li>
          <li v-for="(row, index) in tableData" :key="index" class="item">
            <span class="num bg-555" v-if="index == 0">1</span>
            <span class="num bg-689" v-else-if="index == 1">2</span>
            <span class="num bg-8ea" v-else-if="index == 2">3</span>
            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
            <span class="tag w-300">{{ row.name }}</span>
            <span class="tag">{{ formatterEnum[row.cond.type] }}</span>
            <span class="tag">{{ row.created }}</span>
            <span class="tag">{{ row.createdby_name }}</span>
            <span class="tag">
              <i
                v-if="row.state === 'running'"
                class="item-icon el-icon-loading"
              ></i>
              <var>{{ formatterEnum[row.state] }}</var>
            </span>
            <span :class="{ tag: true, jy: row.state !== 'done' }">
              <el-tooltip effect="dark" content="查看报告" placement="bottom"
                ><i
                  class="iconfont icon-chakan"
                  @click="target(row.id)"
                  v-if="row.state === 'done'"
                ></i>
                <i class="iconfont icon-chakan" v-else></i
              ></el-tooltip>
              <el-dropdown v-if="row.state === 'done'" class="iconfont">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-xiazai"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <i
                      class="iconfont icon-pdf"
                      @click="handleExport('pdf', row)"
                      ><span>导出PDF</span></i
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <i
                      class="iconfont icon-word"
                      @click="handleExport('word', row)"
                      ><span>导出word</span></i
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <i
                      class="iconfont icon-excel"
                      @click="handleExport('excel', row)"
                      ><span>导出Excel</span></i
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-dropdown v-else class="iconfont">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-xiazai"></i>
                </span>
              </el-dropdown>
              <el-tooltip effect="dark" content="删除报告" placement="bottom"
                ><i
                  class="item-icon iconfont icon-shanchu"
                  @click="handleDel(row.id)"
                  v-if="row.state === 'done'"
                ></i>
                <i class="item-icon iconfont icon-shanchu" v-else></i
              ></el-tooltip>
            </span>
          </li>
        </ul>
      </div>
      <div class="list-box">
        <div class="fxbgck">
          <!-- 分页器 -->
          <div class="page-bottom">
            <pagination
              :total="total"
              :page.sync="listQuery.page"
              :size.sync="listQuery.size"
              layout="prev, pager, next"
              @pagination="loadData"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
    <confirm-delete
      class="single-information"
      ref="confirm"
      title="删除分析报告"
      confirm="删除"
      @must="mustDel"
    />
  </el-container>
</template>
<script>
import {
  getReportList,
  delReport,
  state as reportState,
  retry as retryCompare
} from '@/api/compare';
import { reportTableHeaders, formatterEnum, exportItem } from './constants';
import pagination from '@components/common/pagination.vue';
import confirmDelete from '@components/common/confirm.vue';
import { getAuthToken } from '@/utils/helpers';
import { mapGetters } from 'vuex';
const { _ } = window;
export default {
  name: 'ReportList',
  data() {
    return {
      loading: true,
      reportTableHeaders,
      formatterEnum,
      exportItem,
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        size: 10
      },
      nf: null
    };
  },
  props: {
    changeTab: {
      type: Function,
      default: null
    }
  },
  components: {
    pagination,
    confirmDelete
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters(['comparePollFlag'])
  },
  methods: {
    // 设定报告
    createReport() {
      this.$emit('createReport');
    },
    // 拉取列表
    loadData() {
      const _this = this;
      this.loading = true;
      getReportList(this.listQuery).then(res => {
        const { state, error, data } = res.data;
        if (state) {
          data.data.map(item => {
            item.download = false;
          });
          this.tableData = data.data;
          this.total = data.total;
          this.listQuery.page = data.current_page;

          if (!this.comparePollFlag) {
            const closeNf = () => {
              if (this.nf) {
                this.nf.close();
              }
            };

            const list = _.orderBy(data.data, ['id', 'asc']);
            _(list).forEach(item => {
              if (item.state === 'running' || item.state === 'queue') {
                this.$store.commit(
                  'SET_COMPARE_POLL_FLAG',
                  setInterval(() => {
                    reportState({ id: item.id })
                      .then(res => {
                        if (res.data.state) {
                          const { data } = res.data;
                          if (data.state === 'error') {
                            clearInterval(_this.comparePollFlag);
                            _this.$store.commit('SET_COMPARE_POLL_FLAG', null);
                            _this.nf = _this.$notify({
                              type: 'error',
                              title: '失败',
                              dangerouslyUseHTMLString: true,
                              message: _this.renderFail(item),
                              offset: 80,
                              duration: 0,
                              onClick: closeNf
                            });
                          } else if (data.state === 'done') {
                            clearInterval(_this.comparePollFlag);
                            _this.$store.commit('SET_COMPARE_POLL_FLAG', null);
                            _this.loadData();
                            _this.nf = _this.$notify({
                              type: 'success',
                              title: '成功',
                              dangerouslyUseHTMLString: true,
                              message: this.renderSucc(),
                              offset: 80,
                              duration: 0,
                              onClick: closeNf
                            });
                          }
                        }
                      })
                      .catch(e => {
                        clearInterval(_this.comparePollFlag);
                        window.console.error(e);
                      });
                  }, 6000)
                );
                return false;
              }
            });
          }
        } else {
          this.$message({
            message: error || '未知异常，请重试！',
            type: 'error'
          });
        }
        this.loading = false;
      });
    },
    renderSucc() {
      return (
        <p>
          <span class="cor-67c">报告生成成功。</span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.toHistory();
            }}
          >
            点击查看
          </span>
        </p>
      );
    },
    renderFail(item) {
      return (
        <span class="cor-fc5">
          <span>报告“{item.name}”生成失败，请稍后</span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.retry(item.id);
            }}
          >
            重试
          </span>
          ，或联系相关人员。
        </span>
      );
    },
    retry(id) {
      retryCompare({ id })
        .then(res => {
          if (res.data.state) {
            this.$message.success('重新提交该比较报告成功');
            setTimeout(() => {
              this.toHistory();
            }, 2000);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          this.$message.error('重试失败，服务错误');
          window.console.error(e);
        });
    },
    toHistory() {
      this.loadData();

      if (this.$route.name === 'compare') {
        if (this.changeTab) {
          this.changeTab('report-list');
        }
      } else {
        this.$router.push({ name: 'compare', params: { toList: true } });
      }
    },
    // 导出
    handleExport(type, row) {
      const { id } = row;
      const token = getAuthToken();

      switch (type) {
        case 'pdf':
          window.open(
            `${window.service.api}/compare/report/doc?id=${id}&doc_type=pdf&token=${token}`,
            '_blank'
          );
          break;
        case 'word':
          window.open(
            `${window.service.api}/compare/report/doc?id=${id}&doc_type=word&token=${token}`,
            '_blank'
          );
          break;
        case 'excel':
          window.open(
            `${window.service.api}/compare/report/doc?id=${id}&doc_type=excel&token=${token}`,
            '_blank'
          );
          break;
        default:
          break;
      }
    },
    target(id) {
      window.open(`/compareview?id=${id}`, '_blank');
    },
    // 删除
    handleDel(id) {
      this.$refs.confirm.show(
        [{ type: 'error', text: '您将不能再查看及导出此份报告' }],
        { id }
      );
      return false;
    },
    mustDel(data) {
      const { id } = data;
      delReport({ id }).then(res => {
        const { state, error } = res.data;
        if (state) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.loadData();
        } else {
          this.$message({
            type: 'error',
            message: error || '删除失败，请重试！'
          });
        }
      });
    },
    // 打开/关闭 导出下拉框
    switchDownloadBox(row) {
      row.download = !row.download;
      this.tableData.map(item => {
        if (item.id != row.id) item.download = false;
      });
    }
  }
};
</script>
<style scoped>
.report-list .tag {
  width: 12%;
}
.report-list .mod-list-title2 {
  width: 25%;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.report-list li span:nth-child(3) {
  width: 16%;
}
.report-list li span:nth-child(7),
.report-list li span:nth-child(8),
.report-list li span:nth-child(9) {
  width: 6%;
}
</style>
