<template>
  <el-container class="w-100 compare-set">
    <el-row class="empty" v-if="data.length < 1 && !loading">
      <div>
        <img
          src="@/assets/images/common/empty/bjsd.png"
          alt="比较设定"
          width="400"
          class="img"
          type="image/svg+xml"
        />
        <p>
          <span>什么都没有，快来储存常用比较吧</span
          ><el-button type="primary" round @click="setCompare(null)"
            >设定常用</el-button
          >
        </p>
      </div>
    </el-row>
    <div class="min-h-600 w-100" v-loading="loading" v-else>
      <div class="mod-list lh-60 average-list">
        <ul>
          <li>
            <h3>
              <span class="num-tit">序号</span>
              <span class="tag">设定名称</span>
              <span class="tag">比较类型</span>
              <span class="tag">创建日期</span>
              <span class="tag">比较项目</span>
              <span class="tag">操作</span>
            </h3>
          </li>
          <li v-for="(row, index) in data" :key="index">
            <div class="item">
              <span class="num bg-555" v-if="index == 0">1</span>
              <span class="num bg-689" v-else-if="index == 1">2</span>
              <span class="num bg-8ea" v-else-if="index == 2">3</span>
              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
              <span class="tag">{{ row.name }}</span>
              <span class="tag">{{ typeMap[row.cond.type] }}</span>
              <span class="tag">{{ row.created }}</span>
              <span class="tag">{{ row.cond.desc }}</span>
              <span class="tag">
                <el-tooltip effect="dark" content="生成报告" placement="bottom"
                  ><i
                    class="iconfont icon-shengchengbaogao"
                    @click="generate(row)"
                    v-loading="row.generateLoading"
                  ></i
                ></el-tooltip>
                <el-tooltip effect="dark" content="套用设定" placement="bottom"
                  ><i
                    class="iconfont icon-taoyong"
                    @click="setCompare(row.id)"
                  ></i
                ></el-tooltip>
                <el-tooltip effect="dark" content="修改设定" placement="bottom"
                  ><i
                    class="iconfont icon-bianji djh"
                    @click="handleEdit(row)"
                    v-if="row.edited"
                  ></i
                  ><i
                    class="iconfont icon-bianji"
                    @click="handleEdit(row)"
                    v-else
                  ></i
                ></el-tooltip>
                <el-tooltip effect="dark" content="删除设定" placement="bottom"
                  ><i
                    class="iconfont icon-shanchu"
                    @click="del(row.id, index)"
                  ></i
                ></el-tooltip>
              </span>
            </div>
            <!-- 编辑 -->
            <edit
              v-if="row.edited"
              :row="row"
              @editDone="loadData"
              @cancleEdit="handleCancle(row)"
            />
          </li>
        </ul>
      </div>
      <!-- 分页器 -->
      <div class="page-bottom">
        <el-pagination
          :hide-on-single-page="singPage"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <confirm-delete
      class="single-information"
      ref="confirm"
      title="删除比较设定"
      confirm="删除"
      @must="mustDel"
    />
  </el-container>
</template>
<script type="application/javascript">
import Edit from './components/edit';
import {
  lists as compareList,
  submitReport,
  storeSetup,
  delSetup
} from '@/api/compare';
import confirmDelete from '@components/common/confirm.vue';
export default {
  name: 'compare-list',
  components: {
    Edit,
    confirmDelete
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      data: [],
      loading: true,
      typeMap: {
        multi_plan: '多方案竞品比较',
        multi_dimension: '单方案不同维度比较',
        multi_time: '单方案不同时间比较'
      },
      singPage: true
    };
  },
  filters: {},
  mounted() {
    this.loadData();
  },
  methods: {
    generate(row) {
      if (row.generateLoading) return false;
      row.generateLoading = true;
      submitReport({ cond: row.cond })
        .then(res => {
          if (res.data.state) {
            this.$message.success('报告生成中，请在报告列表中查看');
          } else {
            if (res.data.error.indexOf('删除') !== -1) {
              this.$message.warning(res.data.error);
            } else {
              this.$message.error(res.data.error);
            }
          }
          row.generateLoading = false;
        })
        .catch(err => {
          this.$message.error('生成失败，服务错误');
        });
    },
    // 取消修改
    handleCancle(row) {
      row.edited = false;
    },
    // 修改
    handleEdit(row) {
      row.edited = !row.edited;
    },
    loadData() {
      this.loading = true;
      compareList({ size: 10, page: this.currentPage })
        .then(res => {
          if (res.data.state) {
            res.data.data.forEach(item => {
              item.edited = false;
              item.generateLoading = false;
            });
            this.data = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error('获取设定比较失败，服务错误');
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    setCompare(id) {
      this.$emit('setCompare', id);
    },
    del(id, index) {
      this.$refs.confirm.show(
        [{ type: 'error', text: '您将不能再导出此设定条件' }],
        { id, index }
      );
    },
    mustDel(data) {
      const { id, index } = data;
      delSetup({ id: id })
        .then(res => {
          if (res.data.state) {
            this.$message.success('删除成功');
            this.data.splice(index, 1);
            if (this.data.length < 1) {
              this.loadData();
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('删除失败，服务错误');
        });
    }
  }
};
</script>
<style scoped>
.compare-set li {
  height: auto;
}
</style>
