<template>
  <div class="bjfasd">
    <div class="box_shadow"></div>
    <el-row class="mar-t-10">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">比较类型</span>
      </el-col>
      <el-col :span="21">
        <el-radio v-model="compareType" label="multi_plan"
          >多方案竞品比较
        </el-radio>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col :span="21" class="mar-l-120">
        <el-radio v-model="compareType" label="multi_dimension" class="mar-r-18"
          >单方案不同维度比较
        </el-radio>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col :span="21" class="mar-l-120">
        <el-radio v-model="compareType" label="multi_time" class="mar-r-18"
          >单方案不同时间比较
        </el-radio>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col class="w-120">
        <span class="lh-32 b fz-14"
          >{{ compareType === 'multi_plan' ? '主要' : '分析' }}方案</span
        >
      </el-col>
      <el-col :span="21">
        <el-select placeholder="请选择方案" v-model="plan_id">
          <el-option
            :value="row.pid"
            v-for="(row, index) in plans"
            :key="index"
            :label="row.name"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="mar-t-10" v-show="compareType === 'multi_plan'">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">竞品方案</span>
      </el-col>
      <el-col :span="21" v-if="plan_ids.length >= 1">
        <el-select placeholder="请选择方案" v-model="plan_ids[0]">
          <el-option
            :value="row.pid"
            v-for="(row, index) in plans"
            :key="index"
            :label="row.name"
          ></el-option>
        </el-select>
        <i class="iconfont icon-guanbi" @click="removeItem('plan', 0)"></i>
      </el-col>
    </el-row>
    <el-row
      class="mar-t-10"
      v-for="(plan, index) in plan_ids"
      :key="index"
      v-if="index >= 1 && compareType === 'multi_plan'"
    >
      <el-col :span="21" class="mar-l-120">
        <el-select placeholder="请选择方案" v-model="plan_ids[index]">
          <el-option
            :value="row.pid"
            v-for="(row, index) in plans"
            :key="index"
            :label="row.name"
          ></el-option>
        </el-select>
        <i class="iconfont icon-guanbi" @click="removeItem('plan', index)"></i>
      </el-col>
    </el-row>
    <el-row class="mar-t-10" v-if="compareType === 'multi_plan'">
      <el-col :span="21" class="mar-l-120" v-if="plan_ids.length <= 4">
        <el-button
          type="plain"
          size="mini"
          @click="addItem('plan')"
          icon="iconfont icon-zengjia"
          >新增竞品方案</el-button
        >
      </el-col>
    </el-row>
    <el-row
      class="mar-t-10"
      v-if="compareType === 'multi_plan' || compareType === 'multi_dimension'"
    >
      <el-col class="w-120">
        <span class="lh-32 b fz-14">分析时间</span>
      </el-col>
      <el-col :span="21">
        <el-date-picker
          style="top:1px;"
          v-model="span"
          :clearable="false"
          :picker-options="{
            disabledDate: dateRange,
            shortcuts: pickerOptions.shortcuts
          }"
          :default-time="['00:00:00', '23:59:59']"
          :editable="false"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <!-- 比较时区配置 spans -->
    <el-row class="mar-t-10" v-if="compareType === 'multi_time'">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">比较时间</span>
      </el-col>
      <el-col :span="21" v-if="spans.length >= 1">
        <el-input
          class="w-120 mar-r-10"
          v-model.trim="spans[0].name"
          :maxlength="6"
        >
          <var slot="suffix" class="input-suffix"
            >{{ spans[0].name.length }}/6</var
          >
        </el-input>
        <el-date-picker
          style="top:1px;"
          v-model="spans[0].range"
          :clearable="false"
          :picker-options="{
            disabledDate: dateRange,
            shortcuts: pickerOptions.shortcuts
          }"
          :default-time="['00:00:00', '23:59:59']"
          :editable="false"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <i class="iconfont icon-guanbi" @click="removeItem('time', 0)"></i>
      </el-col>
    </el-row>
    <el-row
      class="mar-t-10"
      v-for="(row, index) in spans"
      :key="index"
      v-if="index >= 1 && compareType === 'multi_time'"
    >
      <el-col :span="21" class="mar-l-120">
        <el-input class="w-120 mar-r-10" v-model.trim="row.name" :maxlength="6">
          <var slot="suffix" class="input-suffix">{{ row.name.length }}/6</var>
        </el-input>
        <el-date-picker
          v-model="row.range"
          :clearable="false"
          :picker-options="{
            disabledDate: dateRange,
            shortcuts: pickerOptions.shortcuts
          }"
          :default-time="['00:00:00', '23:59:59']"
          :editable="false"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <i class="iconfont icon-guanbi" @click="removeItem('time', index)"></i>
      </el-col>
    </el-row>
    <el-row class="mar-t-10" v-if="compareType === 'multi_time'">
      <el-col :span="21" class="mar-l-120" v-if="spans.length <= 5">
        <el-button
          type="plain"
          size="mini"
          @click="addItem('time')"
          icon="iconfont icon-zengjia"
          >新增时区</el-button
        >
      </el-col>
    </el-row>
    <!-- 比较维度配置 dimensions -->
    <el-row class="mar-t-10" v-if="compareType === 'multi_dimension'">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">比较维度*</span>
      </el-col>
      <el-col :span="21" class="gjcsd" v-if="dimensions.length >= 1">
        <el-input
          class="w-120 f-l mar-r-10"
          v-model.trim="dimensions[0].name"
          :maxlength="6"
        >
          <var slot="suffix" class="input-suffix"
            >{{ dimensions[0].name.length }}/6</var
          >
        </el-input>
        <el-input
          class="w-190 f-l mar-r-10"
          placeholder="多个关键词请用英文逗号隔开"
          v-model.trim="dimensions[0].must"
        ></el-input>
        <span class="f-l mar-r-10">关键词关系</span>
        <el-select class="w-90 f-l mar-r-10" v-model="dimensions[0].op">
          <el-option label="与" value="and"></el-option>
          <el-option label="或" value="or"></el-option>
        </el-select>
        <span class="f-l mar-r-10">排除</span>
        <el-input
          class="w-280 f-l"
          placeholder="多个关键词请用英文逗号隔开"
          v-model="dimensions[0].not"
        ></el-input>
        <i class="iconfont icon-guanbi" @click="removeItem('word', 0)"></i>
      </el-col>
      <el-col :span="21" v-else>
        <el-button
          type="plain"
          size="mini"
          @click="addItem('word')"
          icon="iconfont icon-zengjia"
          >新增维度</el-button
        >
      </el-col>
    </el-row>
    <el-row
      class="mar-t-10 gjcsd"
      v-for="(row, index) in dimensions"
      :key="index"
      v-if="index >= 1 && compareType === 'multi_dimension'"
    >
      <el-col :span="21" class="mar-l-120">
        <el-input class="w-120 f-l mar-r-10" v-model="row.name" :maxlength="6">
          <var slot="suffix" class="input-suffix">{{ row.name.length }}/6</var>
        </el-input>
        <el-input
          class="w-190 f-l mar-r-10"
          placeholder="多个关键词请用英文逗号隔开"
          v-model.trim="row.must"
        ></el-input>
        <span class="f-l mar-r-10">关键词关系</span>
        <el-select class="w-90 f-l mar-r-10" v-model="row.op">
          <el-option label="与" value="and"></el-option>
          <el-option label="或" value="or"></el-option>
        </el-select>
        <span class="f-l mar-r-10">排除</span>
        <el-input
          class="w-280 f-l"
          placeholder="多个关键词请用英文逗号隔开"
          v-model="row.not"
        ></el-input>
        <i class="iconfont icon-guanbi" @click="removeItem('word', index)"></i>
      </el-col>
    </el-row>
    <el-row
      class="mar-t-10"
      v-if="
        dimensions.length >= 1 &&
          dimensions.length <= 5 &&
          compareType === 'multi_dimension'
      "
    >
      <el-col :span="21" class="mar-l-120">
        <el-button
          type="plain"
          size="mini"
          @click="addItem('word')"
          icon="iconfont icon-zengjia"
          >新增维度</el-button
        >
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">报告名称</span>
      </el-col>
      <el-col :span="21">
        <el-input
          class="w-310"
          v-model="name"
          :maxlength="20"
          show-word-limit
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">副标题 </span>
      </el-col>
      <el-col :span="21">
        <el-input
          class="w-430"
          v-model="subtitle"
          :maxlength="30"
          placeholder="(非必填)请输入副标题"
          show-word-limit
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col class="w-120">
        <span class="lh-32 b fz-14">主题颜色</span>
      </el-col>
      <el-col :span="21" class="theme_colors lh-30">
        <div>
          <div v-for="item in themeList" :key="item.label">
            <el-radio :label="item.label" v-model="theme_color">{{
              item.name
            }}</el-radio>
            <span
              :style="{ background: item.color }"
              class="item-theme-preview"
            ></span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="mar-t-10">
      <el-col :span="24">
        <el-button plain size="mini" @click="highFilter = !highFilter"
          >高级筛选</el-button
        >
      </el-col>
    </el-row>
    <el-form class="gjsx-cont mar-t-10" v-if="highFilter" label-width="100px">
      <el-form-item label="媒体类型" class="mtlx">
        <div class="flex flex-1">
          <el-checkbox
            :indeterminate="filter.isIndeterminate"
            v-model="filter.checkAll"
            @change="handleCheckAllChange()"
            class="mar-r-20"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="filter.media"
            @change="checkboxChange()"
            class="checkbox-group"
          >
            <el-checkbox
              v-for="(media, index) in medias"
              :label="media"
              :key="index"
              >{{ media }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item label="信息匹配">
        <el-radio-group class="radio_group" v-model="filter.field">
          <el-radio label="content">全文匹配</el-radio>
          <el-radio label="title">标题匹配</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="语言">
        <el-radio-group class="radio_group" v-model="filter.lang">
          <el-radio label="all">全部</el-radio>
          <el-radio label="zh">中文</el-radio>
          <el-radio label="en">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="微博类型">
        <el-radio-group class="radio_group" v-model="filter.weibo_forward">
          <el-radio label="all">不限</el-radio>
          <el-radio label="yes">转发</el-radio>
          <el-radio label="no">原创</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="微博用户类型">
        <el-radio-group class="radio_group" v-model="filter.weibo_usertype">
          <el-radio label="all">不限</el-radio>
          <el-radio label="普通用户">普通用户</el-radio>
          <el-radio label="黄V">黄V</el-radio>
          <el-radio label="金V">金V</el-radio>
          <el-radio label="蓝V">蓝V</el-radio>
          <el-radio label="达人">达人</el-radio>
          <el-radio label="微博女郎">微博女郎</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="作者">
        <el-input
          class="w-280"
          placeholder="多个作者请用英文逗号隔开"
          v-model="filter.author"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-row class="mar-t-45" style="width:900px;">
      <div class="button-group">
        <el-button
          type="primary"
          round
          @click="save()"
          v-loading="saveLoading"
          :disabled="saveLoading"
          >确定</el-button
        >
        <el-button round @click="cancelLoad()">取消</el-button>
      </div>
    </el-row>
  </div>
</template>
<script>
import { dateRangeDisabled, parseTime } from '@/utils/helpers.js';
import { themeList, pickerOptions } from '@/utils/constants';
import { setup } from '@/api/compare';
import { mapGetters } from 'vuex';
import { medias } from '@/utils/constants';

export default {
  name: 'Edit',
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      themeList,
      pickerOptions,
      name: '',
      compareType: 'multi_plan',
      plan_id: '',
      subtitle: '',
      theme_color: 'blue',
      plan_ids: [''],
      dimensions: [{ name: '维度一', must: '', op: 'and', not: '' }],
      spans: [{ name: '时区一', range: '' }],
      span: '',
      highFilter: false,
      filter: {
        isIndeterminate: false,
        checkAll: true,
        media: medias,
        lang: 'all',
        field: 'content',
        weibo_forward: 'all',
        weibo_usertype: 'all',
        author: ''
      },
      compareId: null,
      selectedCompare: null,
      setCompare: { has: false, compareId: null },
      compareLoading: true,
      compares: [],
      numberMap: ['一', '二', '三', '四', '五', '六'],
      loading: false,
      saveLoading: false
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['plans', 'medias'])
  },
  watch: {
    plan_id: function(pid) {
      let plan = _.find(this.plans, { pid: pid });
      this.name = plan.name;
    }
  },
  methods: {
    init() {
      const cond = this.row.cond;
      this.$set(this.filter, 'media', cond.media);
      this.field = cond.field;
      this.lang = cond.lang;
      this.weibo_forward = cond.weibo_forward;
      this.weibo_usertype = cond.weibo_usertype;
      this.author = cond.author;
      this.compareType = cond.type;
      this.plan_id = cond.plan_id;
      this.name = cond.report_name;
      this.subtitle = cond.subtitle;
      this.theme_color = cond.theme_color;
      switch (cond.type) {
        case 'multi_plan':
          this.plan_ids = cond.plan_ids;
          var span = cond.span.split('~');
          this.span = [
            parseTime(new Date(span[0]), 'y-m-d h:i:s'),
            parseTime(new Date(span[1]), 'y-m-d h:i:s')
          ];
          break;
        case 'multi_dimension':
          this.dimensions = cond.dimensions;
          var span = cond.span.split('~');
          this.span = [
            parseTime(new Date(span[0]), 'y-m-d h:i:s'),
            parseTime(new Date(span[1]), 'y-m-d h:i:s')
          ];
          break;
        case 'multi_time':
          var spans = [];
          cond.spans.map(span => {
            spans.push({
              name: span.name,
              range: [
                parseTime(new Date(span.start), 'y-m-d h:i:s'),
                parseTime(new Date(span.end), 'y-m-d h:i:s')
              ]
            });
          });
          this.spans = spans;
          break;
      }
    },
    save() {
      let params = this.calculationParams();
      if (!params) return false;
      params.name = this.row.name;
      params.id = this.row.id;
      this.saveLoading = true;
      setup(params)
        .then(res => {
          if (res.data.state) {
            this.$message.success('保存成功');
            this.$emit('editDone');
          } else {
            this.$message.error(res.data.error);
          }
          this.saveLoading = false;
        })
        .catch(res => {
          this.$message.error('保存设定失败，服务错误');
        });
    },
    calculationParams() {
      const _this = this;
      const {
        dimensions,
        spans,
        compareType,
        plan_ids,
        plan_id,
        span,
        name,
        subtitle,
        theme_color,
        filter
      } = this;
      if (!plan_id) {
        this.$message.warning('请选择主要方案');
        return false;
      }
      if (name.trim().length < 1) {
        this.$message.warning('请填写报告名称');
        return false;
      }
      if (filter.media.length < 1) {
        this.$message.warning('请选择媒体类型');
        return false;
      }
      var params = {
        cond: {
          report_name: name.trim(),
          type: compareType,
          plan_id: plan_id,
          subtitle: subtitle.trim(),
          theme_color: theme_color,
          media: filter.media,
          lang: filter.lang,
          field: filter.field,
          weibo_forward: filter.weibo_forward,
          weibo_usertype: filter.weibo_usertype,
          author: filter.author
        }
      };
      switch (compareType) {
        case 'multi_plan':
          var ids = _.filter(plan_ids);
          if (_.size(ids) < 1) {
            this.$message.warning('请选择竞品方案');
            return false;
          }
          if (_.size(span) !== 2) {
            this.$message.warning('请选择分析时间');
            return false;
          }
          params.cond['plan_ids'] = ids;
          params.cond['span'] = `${span[0]}~${span[1]}`;
          return params;
          break;
        case 'multi_dimension':
          if (_.size(span) !== 2) {
            this.$message.warning('请选择分析时间');
            return false;
          }
          var alreadyDimensionName = '';
          for (let i = 0; i < dimensions.length; i++) {
            dimensions[i].name = dimensions[i].name.trim();
            dimensions[i].must = dimensions[i].must.trim();
            dimensions[i].not = dimensions[i].not.trim();
            if (dimensions[i].name.length < 1) {
              _this.$message.warning('比较关键词组名称不能为空');
              return false;
            }
            if (alreadyDimensionName === dimensions[i].name) {
              _this.$message.warning(
                '比较关键词组名称"' + alreadyDimensionName + '"不能重名或为空'
              );
              return false;
            }
            alreadyDimensionName = dimensions[i].name;
            if (
              dimensions[i].must.length == 0 &&
              dimensions[i].not.length == 0
            ) {
              _this.$message.warning(
                '请填写或删除比较关键词"' +
                  dimensions[i].name +
                  '"(主关键词或排除词不能同时为空)'
              );
              return false;
            }
            let must = dimensions[i].must.split(',');
            let not = dimensions[i].not.split(',');
            if (must.length > 20 || not.length > 20) {
              _this.$message.warning(
                dimensions[i].name +
                  '，关键词组个数英文逗号分割（长度不能超过20组）'
              );
              return false;
            }
          }
          params.cond['dimensions'] = dimensions;
          params.cond['span'] = `${span[0]}~${span[1]}`;
          return params;
          break;
        case 'multi_time':
          var alreadyTimeName = '';
          var dates = [];
          for (let j = 0; j < spans.length; j++) {
            spans[j].name = spans[j].name.trim();
            if (spans[j].name.length < 1) {
              _this.$message.warning('比较时间名称不能为空');
              return false;
            }
            if (alreadyTimeName == spans[j].name) {
              _this.$message.warning(
                '比较时间名称"' + alreadyTimeName + '"不能重名'
              );
              return false;
            }
            alreadyTimeName = spans[j].name;
            if (!spans[j].range) {
              _this.$message.warning(
                '请选择或删除比较时间"' + spans[j].name + '"(比较时间不能为空)'
              );
              return false;
            }
            // 格式化
            dates.push({
              name: spans[j].name,
              start: `${spans[j].range[0]}`,
              end: `${spans[j].range[1]}`
            });
          }
          params.cond['spans'] = dates;
          return params;
          break;
      }
    },
    dateRange(date) {
      return dateRangeDisabled(date);
    },
    handleCheckAllChange() {
      this.filter.media = this.filter.checkAll ? this.medias : [];
      this.filter.isIndeterminate = false;
    },
    checkboxChange() {
      let checkedCount = this.medias.length;
      this.filter.checkAll = checkedCount === this.filter.media.length;
      this.filter.isIndeterminate =
        this.filter.media.length > 0 && this.filter.media.length < checkedCount;
    },
    calculationDefaultName(key, name) {
      let source = key == 'time' ? this.spans : this.dimensions;
      let prefix = key == 'time' ? '时区' : '关键词组';
      var names = source.map(function(item) {
        return item.name;
      });
      if (names.indexOf(name) != -1) {
        for (let i in this.numberMap) {
          let newName = prefix + this.numberMap[i];
          if (names.indexOf(newName) == -1) return newName;
        }
      }
      return name;
    },
    addItem(name) {
      switch (name) {
        case 'time':
          if (this.spans.length >= 6) {
            this.$message.warning('最多只能添加6组比较时间');
            return false;
          }
          var i = this.spans.length;
          var name = this.calculationDefaultName(
            'time',
            '时区' + this.numberMap[i]
          );
          this.spans = this.spans.concat([{ name: name, range: '' }]);
          break;
        case 'word':
          if (this.dimensions.length >= 6) {
            this.$message.warning('最多只能添加6组维度比较');
            return false;
          }
          var i = this.dimensions.length;
          var name = this.calculationDefaultName(
            'word',
            '维度' + this.numberMap[i]
          );
          this.dimensions = this.dimensions.concat([
            { name: name, op: 'and', must: '', not: '' }
          ]);
          break;
        case 'plan':
          if (_.size(this.plan_ids) >= 5) {
            this.$message.warning('最多只能添加5组竞品方案');
            return false;
          }
          this.plan_ids = this.plan_ids.concat(['']);
          break;
      }
    },
    removeItem(name, index) {
      switch (name) {
        case 'time':
          if (_.size(this.spans) === 1) {
            this.$message.warning('时区不能为空');
            return false;
          }
          this.spans.splice(index, 1);
          break;
        case 'word':
          if (_.size(this.dimensions) === 1) {
            this.$message.warning('比较维度不能为空');
            return false;
          }
          this.dimensions.splice(index, 1);
          break;
        case 'plan':
          if (_.size(this.plan_ids) === 1) {
            this.$message.warning('至少需要选择一个竞品方案');
            return false;
          }
          this.plan_ids.splice(index, 1);
          break;
      }
    },
    cancelLoad() {
      this.$emit('cancleEdit');
    },
    errTip(str) {
      this.$message.warning(str);
    }
  }
};
</script>
<style lang="scss" scoped>
.bjfasd {
  background: #f9f9f9;
  padding: 20px 30px;
  border-radius: 4px;
  color: #414141;
  font-size: 12px;
}
.bjfasd .el-row {
  line-height: 30px;
}
.w-120 {
  width: 120px;
}
.w-190 {
  width: 190px;
}
.w-365 {
  width: 365px;
}
.mar-l-120 {
  margin-left: 120px;
}
.input-suffix {
  font-style: normal;
  font-size: 13px;
  line-height: 32px;
}
.gjcsd span {
  padding: 0 4px;
}
.el-button--plain {
  border: none;
  line-height: 36px;
  padding: 0;
  background: none;
}
.el-button--plain:focus,
.el-button--plain:hover {
  background: none;
}
.item-theme-preview {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
}
/*高级筛选*/
.echarts_empty {
  height: 320px !important;
}
.input-suffix {
  font-style: normal;
  font-size: 13px;
  line-height: 32px;
}
</style>
<style scoped>
.mtlx_bq >>> .el-form-item__label {
  margin-top: 10px;
}
.bjfasd >>> .icon-zengjia {
  float: left;
  margin-right: 5px;
}
.gjsx-cont >>> .el-checkbox span {
  line-height: 30px;
  vertical-align: middle;
  float: none;
}
.bjfasd >>> .el-button span {
  float: none;
}
</style>
