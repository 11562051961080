<template>
  <div>
    <el-dialog
      class="scjb_Dialog Dialog_style1"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <div class="mod-title" slot="title">{{ title }}</div>
      <div class="cont">
        <div>
          <span>{{ note }} </span>
          <el-input
            class="w-200"
            :maxlength="10"
            v-model.trim="name"
            maxlength="10"
            show-word-limit
          >
            <!-- <var slot="suffix" class="input-suffix hide">{{ name.length }}/10</var> -->
          </el-input>
        </div>
      </div>
      <div class="button-group">
        <el-button type="primary" round @click="inputDialogSubmit"
          >确定</el-button
        >
        <el-button round @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'dialog-input',
  props: ['title', 'note'],
  data() {
    return {
      visible: false,
      name: ''
    };
  },
  watch: {},
  mounted() {},
  methods: {
    show() {
      this.name = '';
      this.visible = true;
    },
    inputDialogSubmit() {
      this.$emit('submit', this.name);
      this.visible = false;
    }
  }
};
</script>
