<template>
  <div v-if="total > 0" class="pagination-box">
    <el-pagination
      :current-page.sync="currPage"
      :page-sizes="pageSizes"
      :page-size.sync="pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
      <template >
        <span v-if="customSlot === 'slot1'" style="margin-right: 0;" class="el-pagination__total">
          {{ currPage }}/{{ Math.ceil(total / pageSize) }}
        </span>
        <span v-else class="el-pagination__total">
          {{ total > 5000 ? "共 " + total + " 条，显示前 5000 条" : "共 " + total + " 条" }}
        </span>
      </template>
    </el-pagination>
  </div>
</template>
<script>
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'pagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default() {
        return [20, 50, 100];
      },
    },
    size: {
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: 'slot, sizes, prev, pager, next, jumper',
    },
    total: {
      type: Number,
      default: 0,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    customSlot: {
      type: String,
      default: '',
    }
  },
  computed: {
    currPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(val) {
        this.$emit('update:size', val);
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currPage, size: val });
      if (this.autoScroll) {
        scrollTo(0, 100);
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, size: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 100);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  // .pagination-box {
  //   background: #fff;
  // }
</style>
