<template>
  <el-container>
      <div class="w-100">
        <div class="content">
          <el-card class="box-card">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="比较报告制作" name="generate">
                <compare-generate
                        ref="generate"
                        v-on:changeTab="changeTab"
                        v-if="activeName === 'generate'" />
              </el-tab-pane>
              <el-tab-pane label="比较设定" name="list">
                <compare-list
                        v-on:setCompare="setCompare"
                        v-on:changeTab="changeTab"
                        v-if="activeName === 'list'" />
              </el-tab-pane>
              <el-tab-pane label="比较报告查看" name="report-list">
                <report-list
                        @change="changeTab"
                        @createReport="createReport"
                        :changeTab="changeTab"
                        v-if="activeName === 'report-list'" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </div>
  </el-container>
</template>
<script type="application/javascript">
  import { tabs } from './constants'
  import Generate from "./generate.vue"
  import List from "./list.vue"
  import { ReportList } from './tab';
  export default {
    name: "compare",
    components: {
      "compare-generate": Generate,
      "compare-list": List,
      ReportList
    },
    data() {
      return {
        tabs,
        activeName: 'generate'
      }
    },
    mounted() {
      if (this.$route.params.hasOwnProperty('toList'))
      {
        this.activeName = 'report-list'
      }
    },
    methods: {
      setCompare(id = null) {
        this.activeName = "generate"

        if (id) {
          this.$nextTick(() => {
            this.$refs.generate.hasSetCompare(id)
          })
        }
      },
      createReport() {
        this.activeName = 'generate';
      },
      handleClick(tab, event) {
      },
      changeTab(name) {
        this.activeName = name
      }
    }
  }
</script>
<style scoped>
</style>
