// 可选类型
export const types = [
  {
    value: "single",
    label: "单方案多维度比较"
  },
  {
    value: "multi",
    label: "多方案竞品比较"
  }
];

// 关键词默认名
export const numberMap = ["一", "二", "三", "四", "五", "六"];

// 比较报告列表
export const reportTableHeaders = [
  {
    prop: "order",
    label: "序号",
    width: "50",
    align: "center"
  },
  {
    prop: "name",
    label: "简报名称",
    width: "",
    align: ""
  },
  {
    prop: "type",
    label: "简报类型",
    width: "",
    align: ""
  },
  {
    prop: "created",
    label: "报告生成时间",
    width: "",
    align: ""
  },
  {
    prop: "total",
    label: "数据量",
    width: "",
    align: ""
  },
  {
    prop: "state",
    label: " 状态",
    width: "",
    align: ""
  },
  {
    prop: "export",
    label: "导出",
    width: "80",
    align: "center"
  },
  {
    prop: "del",
    label: "  删除",
    width: "80",
    align: "center"
  }
];

export const formatterEnum = {
  done: "已生成",
  running: "生成中",
  queue: "生成队列中",
  fail: "生成失败",
  waitting: "生成队列中",
  multi_plan: "多方案竞品比较",
  multi_dimension: "单方案不同维度比较",
  multi_time: "单方案不同时间比较"
};

export const exportItem = [
  {
    icon: "pdf",
    label: "导出pdf",
    value: "pdf"
  },
  {
    icon: "word",
    label: "导出word文档",
    value: "word"
  },
  {
    icon: "h5",
    label: "导出h5",
    value: "h5"
  }
];
