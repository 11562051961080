import { parseTime } from '@/utils/helpers.js'
export const numberMap = ['一', '二', '三', '四', '五', '六'];

export const tabs = [
  {
    name: '比较报告制作',
    label: 'generate',
  },
  {
    name: '比较设定',
    label: 'list',
  },
  {
    name: '比较报告查看',
    label: 'report-list',
  },
]

export function get2span(timeString) {
  let spans = timeString.split(",")
  let span = []

  spans.map((item) => {
    span = span.concat(item.split("~"))
  })

  span = window._.sortBy(span, (date) => {
    return date;
  })

  let begin = parseTime(new Date(window.$moment(window._.first(span)).valueOf()), 'y-m-d');
  let end = parseTime(new Date(window.$moment(window._.last(span)).valueOf()), 'y-m-d');

  return `${begin} 00:00:00~${end} 23:59:59`
}
