<template>
  <el-container>
    <div class="w-100 yqbj pb-10">
      <el-form label-width="100px" class="form-box">
        <el-form-item label="比较类型">
          <div>
            <el-radio v-model="compareType" label="multi_plan">多方案竞品比较</el-radio><br>
            <el-radio v-model="compareType" label="multi_dimension">单方案不同维度比较</el-radio><br>
            <el-radio v-model="compareType" label="multi_time">单方案不同时间比较</el-radio>
          </div>
        </el-form-item>
        <el-form-item>
          <span slot="label">{{ compareType === 'multi_plan' ? '主要' : '分析' }}方案</span>
          <el-select placeholder="请选择方案" v-model="plan_id">
            <el-option
              :value="row.pid"
              v-for="(row, index) in plans"
              :key="index"
              :disabled="plan_ids.indexOf(row.pid) !== -1"
              :label="row.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="竞品方案" v-show="compareType === 'multi_plan'">
          <div v-if="plan_ids.length >= 1">
            <el-select placeholder="请选择方案" v-model="plan_ids[0]">
              <el-option
                :value="row.pid"
                v-for="(row, index) in plans"
                :key="index"
                :disabled="plan_ids.indexOf(row.pid) !== -1 || row.pid === plan_id"
                :label="row.name">
              </el-option>
            </el-select>
            <i class="iconfont icon-guanbi" @click="removeItem('plan', 0)" v-show="plan_ids.length >= 2"></i>
          </div>
        </el-form-item>
        <el-form-item v-for="(plan, index) in plan_ids" :key="index" v-if="index >= 1 && compareType === 'multi_plan'">
          <el-select placeholder="请选择方案" v-model="plan_ids[index]">
            <el-option
              :value="row.pid"
              v-for="(row, index) in plans"
              :key="index"
              :disabled="plan_ids.indexOf(row.pid) !== -1 || row.pid === plan_id"
              :label="row.name"></el-option>
          </el-select>
          <i class="iconfont icon-guanbi" @click="removeItem('plan', index)"></i>
        </el-form-item>
        <el-form-item v-if="compareType === 'multi_plan'" class="mar_t-10">
          <div :span="21" v-if="plan_ids.length <= 4">
            <el-button plain @click="addItem('plan')" icon="iconfont icon-zengjia" class="bd-n">新增竞品方案
            </el-button>
          </div>
        </el-form-item>
        <el-form-item label="分析时间" v-if="compareType === 'multi_plan' || compareType === 'multi_dimension'">
          <el-date-picker class="w-310"
            v-model="span"
            :clearable="false"
            :picker-options="{disabledDate: dateRange, shortcuts: pickerOptions.shortcuts}"
            :default-time="['00:00:00', '23:59:59']"
            :editable="false"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <!-- 比较时区配置 spans -->
        <el-form-item label="比较时间" v-if="compareType === 'multi_time'">
          <div v-if="spans.length >= 1">
            <el-input class="w-120 mar-r-10" v-model.trim="spans[0].name" :maxlength="6">
              <var slot="suffix" class="input-suffix">{{ spans[0].name.length }}/6</var>
            </el-input>
            <el-date-picker class="w-310"
              v-model="spans[0].range"
              :clearable="false"
              :picker-options="{disabledDate: dateRange, shortcuts: pickerOptions.shortcuts}"
              :default-time="['00:00:00', '23:59:59']"
              :editable="false"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <i
              class="iconfont icon-guanbi"
              @click="removeItem('time', 0)"
              v-show="spans.length > 2">
            </i>
          </div>
        </el-form-item>
        <el-form-item v-for="(row, index) in spans" :key="index" v-if="index >= 1 && compareType === 'multi_time'">
          <el-input class="w-120 mar-r-10" v-model.trim="row.name" :maxlength="6">
            <var slot="suffix" class="input-suffix">{{ row.name.length }}/6</var>
          </el-input>
          <el-date-picker class="w-310"
            v-model="row.range"
            :clearable="false"
            :picker-options="{disabledDate: dateRange, shortcuts: pickerOptions.shortcuts}"
            :default-time="['00:00:00', '23:59:59']"
            :editable="false"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <i
            class="iconfont icon-guanbi"
            @click="removeItem('time', index)"
            v-show="(index === 1 && spans.length > 2) || index > 1"></i>
        </el-form-item>
        <el-form-item v-if="compareType === 'multi_time'" class="mar_t-10">
          <div v-if="spans.length <= 5">
            <el-button plain @click="addItem('time')" icon="iconfont icon-zengjia" class="bd-n">新增时区</el-button>
          </div>
        </el-form-item>
        <!-- 比较维度配置 dimensions -->
        <el-form-item label="比较维度*" v-if="compareType === 'multi_dimension'">
          <div v-if="dimensions.length >= 1">
            <el-input class="w-120 mar-r-10" v-model.trim="dimensions[0].name" :maxlength="6">
              <var slot="suffix" class="input-suffix">{{ dimensions[0].name.length }}/6</var>
            </el-input>
            <el-input placeholder="多个关键词请用英文逗号隔开" v-model.trim="dimensions[0].must" class="w-280 mar-r-10"></el-input>
            <span class="mar-r-10">关键词关系</span>
            <el-select class="w-90 mar-r-10" v-model="dimensions[0].op">
              <el-option label="与" value="and"></el-option>
              <el-option label="或" value="or"></el-option>
            </el-select>
            <span class="mar-r-10">排除</span>
            <el-input placeholder="多个关键词请用英文逗号隔开" v-model="dimensions[0].not" class="w-280"></el-input>
            <i class="iconfont icon-guanbi" @click="removeItem('word', 0)" v-show="dimensions.length > 2"></i>
          </div>
          <div v-else class="mar_t-10">
            <el-button plain @click="addItem('word')" icon="iconfont icon-zengjia" class="bd-n">新增维度</el-button>
          </div>
        </el-form-item>
        <!-- 比较维度配置 dimensions -->
        <el-form-item v-for="(row, index) in dimensions" :key="index" v-if="index >= 1 && compareType === 'multi_dimension'">
          <div>
            <el-input class="w-120 mar-r-10" v-model="row.name" :maxlength="6">
              <var slot="suffix" class="input-suffix">{{ row.name.length }}/6</var>
            </el-input>
            <el-input placeholder="多个关键词请用英文逗号隔开" v-model.trim="row.must" class="w-280 mar-r-10"></el-input>
            <span class="mar-r-10">关键词关系</span>
            <el-select class="w-90 mar-r-10" v-model="row.op">
              <el-option label="与" value="and"></el-option>
              <el-option label="或" value="or"></el-option>
            </el-select>
            <span class="mar-r-10">排除</span>
            <el-input placeholder="多个关键词请用英文逗号隔开" class="w-280" v-model="row.not"></el-input>
            <i class="iconfont icon-guanbi"
              @click="removeItem('word', index)"
              v-show="(index === 1 && dimensions.length > 2) || index > 1">
            </i>
          </div>
        </el-form-item>
        <el-form-item v-if="dimensions.length >= 1 && dimensions.length <= 5 && compareType === 'multi_dimension'" class="mar_t-10">
          <div>
            <el-button plain @click="addItem('word')" icon="iconfont icon-zengjia" class="bd-n">新增维度</el-button>
          </div>
        </el-form-item>
        <el-form-item label="报告名称">
          <el-input class="w-310" v-model="name" :maxlength="20" show-word-limit placeholder="请输入报告名称"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input class="w-430" v-model="subtitle" :maxlength="30" placeholder="(非必填)请输入副标题" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="主题颜色">
          <div class="theme_colors lh-30">
            <div v-for="item in themeList" :key="item.label">
              <el-radio :label="item.label" v-model="theme_color">{{ item.name }}</el-radio>
              <span :style="{ background: item.color }" class="item-theme-preview"></span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="bjfasd">
        <el-row class="mar-t-10">
          <el-col :span="24">
            <el-button plain size="mini" @click="highFilter = !highFilter">高级筛选</el-button>
          </el-col>
        </el-row>
        <el-form label-width="100px" class="form-box mar-t-10 gjsx-cont" v-if="highFilter">
          <el-form-item label="媒体类型" class="mtlx_bq">
            <div class="mtlx flex flex-1">
              <el-checkbox :indeterminate="filter.isIndeterminate" v-model="filter.checkAll" @change="handleCheckAllChange()" class="mar-t-10">全选
              </el-checkbox>
              <el-checkbox-group v-model="filter.media" @change="checkboxChange()" class="checkbox-group mar-t-10">
                <el-checkbox v-for="(media, index) in medias" :label="media" :key="index">{{media}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="信息匹配">
            <el-radio-group class="radio_group" v-model="filter.field">
              <el-radio label="content">全文匹配</el-radio>
              <el-radio label="title">标题匹配</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="语言">
            <el-radio-group class="radio_group" v-model="filter.lang">
              <el-radio label="all">全部</el-radio>
              <el-radio label="zh">中文</el-radio>
              <el-radio label="en">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="微博类型">
            <el-radio-group class="radio_group" v-model="filter.weibo_forward">
              <el-radio label="all">不限</el-radio>
              <el-radio label="yes">转发</el-radio>
              <el-radio label="no">原创</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="微博用户类型">
            <el-radio-group class="radio_group" v-model="filter.weibo_usertype">
              <el-radio label="all">不限</el-radio>
              <el-radio label="普通用户">普通用户</el-radio>
              <el-radio label="黄V">黄V</el-radio>
              <el-radio label="金V">金V</el-radio>
              <el-radio label="蓝V">蓝V</el-radio>
              <el-radio label="达人">达人</el-radio>
              <el-radio label="微博女郎">微博女郎</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="作者" label-width="100px">
            <el-input class="w-310" placeholder="多个作者请用英文逗号隔开" v-model="filter.author"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="mar-t-45">
          <div class="button-group">
            <el-button type="primary" round :disabled="true" v-if="!canBuild">生成报告</el-button>
            <el-button type="primary" round class="selected" @click="generate()" v-loading="generateLoading" :disabled="generateLoading" v-else-if="canBuild">生成报告</el-button>
            <el-button round @click="showSave()">保存设定</el-button>
          </div>
        </el-row>
      </div>
    </div>
    <input-dialog ref="input" title="保存设定" note="比较设定名称：" v-on:submit="saveSetting"/>
  </el-container>
</template>
<script type="application/javascript">
  import inputDialog from "@components/common/input.vue"
  import {dateRangeDisabled, parseTime} from '@/utils/helpers.js'
  import {themeList, medias, pickerOptions} from '@/utils/constants';
  import { lists as compareList, submitReport, storeSetup } from "@/api/compare";
  import {mapGetters} from 'vuex';
  export default {
    name: "compare-generate",
    components: {
      inputDialog
    },
    data() {
      return {
        pickerOptions,
        themeList,
        name: "",
        compareType: "multi_plan",
        plan_id: '',
        subtitle: '',
        theme_color: 'blue',
        plan_ids: [''],
        dimensions: [{name: "维度一", must: "", op: "and", not: ""}, {name: "维度二", must: "", op: "and", not: ""}],
        spans: [{name: "时区一", range: ""}, {name: "时区二", range: ""}],
        span: [],
        highFilter: false,
        filter: {
          isIndeterminate: false, checkAll: true, media: medias, lang: "all", field: "content",
          weibo_forward: 'all', weibo_usertype: 'all', author: ''
        },
        compareId: null,
        selectedCompare: null,
        setCompare: {has: false, compareId: null},
        compareLoading: true,
        compares: [],
        numberMap: ["一", "二", "三", "四", "五", "六"],
        isRouterAlive: true,
        loading: false,
        saveLoading: false,
        generateLoading: false,
        isDestroyed: false,
        typeMap: {multi_plan: '多方案竞品比较', multi_dimension: '单方案不同维度比较', multi_time: '单方案不同时间比较'},
        canBuild: false,
        timeoutFlag: null
      }
    },
    computed: {
      ...mapGetters([
        'plans',
        'medias'
      ])
    },
    mounted() {
      this.loadCompare()
    },
    destroyed() {
      this.isDestroyed = true
      if (this.timeoutFlag) {
        clearTimeout(this.timeoutFlag)
      }
    },
    watch: {
      'plan_id': function (pid) {
        this.rename()
      },
      'compareType': function (type) {
        this.rename()
      },
      'spans': {
        deep: true,
        handler: function (val) {
          this.canBuild = this.canGenerate();
        }
      },
      'dimensions': {
        deep: true,
        handler: function (val) {
          this.canBuild = this.canGenerate();
        }
      },
      'span': function (span) {
        this.canBuild = this.canGenerate();
      },
      'plan_ids': function (ids) {
        this.canBuild = this.canGenerate();
      },
      'name': function (name) {
        this.canBuild = this.canGenerate();
      }
    },
    methods: {
      rename() {
        let plan = _.find(this.plans, {pid: this.plan_id})
        this.name = ''
        if (plan !== undefined) {
          this.name = `${plan.name}-${this.typeMap[this.compareType]}`
        }
      },
      reload() {
        // reload 重置状态
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      },
      loadCompare() {
        this.compareLoading = true
        compareList({size: 100, page: 1}).then(res => {
          if (res.data.state) {
            let result = res.data.data
            this.compares = result
            // 列表处套用设定
            if (this.setCompare.has) {
              this.compareId = this.setCompare.compareId
              this.compareChange(this.compareId)
            }
          } else {
            this.$message.error(res.data.error)
          }
          this.compareLoading = false
        }).catch(res => {
          this.$message.error("加载比较设定失败，服务错误")
        })
      },
      hasSetCompare(id) {
        this.setCompare.has = true
        this.setCompare.compareId = id
        // axios 加载完成先于设置 进行二次加载
        if (!this.compareLoading) this.loadCompare()
      },
      compareChange(id) {
        let row = _.find(this.compares, {id: id})
        const cond = row.cond;
        this.$set(this.filter, 'media', cond.media)
        this.field = cond.field;
        this.lang = cond.lang;
        this.weibo_forward = cond.weibo_forward;
        this.weibo_usertype = cond.weibo_usertype;
        this.author = cond.author;
        this.compareType = cond.type;
        this.name = cond.report_name;
        this.subtitle = cond.subtitle;
        this.theme_color = cond.theme_color;
        this.plan_id = _.find(this.plans, {pid: cond.plan_id}) === undefined ? '' : cond.plan_id;
        if (_.find(this.plans, {pid: cond.plan_id}) === undefined){
          this.$message.warning("该方案被删除或未启用");
        }
        switch (cond.type) {
          case 'multi_plan':
            this.plan_ids = cond.plan_ids.filter((pid) => {
              return _.find(this.plans, {pid: pid}) !== undefined
            });
            this.plan_ids = _.size(this.plan_ids) < 1 ? [''] : this.plan_ids;
            var span = cond.span.split("~");
            this.span = [parseTime(new Date(window.$moment(span[0]).valueOf()), 'y-m-d h:i:s'), parseTime(new Date(window.$moment(span[1]).valueOf()), 'y-m-d h:i:s')];
            break;
          case 'multi_dimension':
            this.dimensions = cond.dimensions;
            var span = cond.span.split("~");
            this.span = [parseTime(new Date(window.$moment(span[0]).valueOf()), 'y-m-d h:i:s'), parseTime(new Date(window.$moment(span[1]).valueOf()), 'y-m-d h:i:s')];
            break;
          case 'multi_time':
            var spans = []
            cond.spans.map((span) => {
              spans.push({
                name: span.name,
                range: [parseTime(new Date(window.$moment(span.start).valueOf()), 'y-m-d h:i:s'), parseTime(new Date(window.$moment(span.end).valueOf()), 'y-m-d h:i:s')]
              })
            })
            this.spans = spans
            break;
        }
      },
      generate() {
        let params = this.calculationParams();
        if (!params) return false;
        this.generateLoading = true
        submitReport(params).then(res => {
          if (res.data.state) {
            this.$message.success("报告生成中，请在报告列表中查看")
            this.timeoutFlag = setTimeout(() => {
              this.$emit('changeTab', 'report-list')
            }, 2000)
          } else {
            this.$message.error(res.data.error)
          }
          this.generateLoading = false
        }).catch(res => {
          this.$message.error("生成失败，服务错误")
        })
      },
      showSave() {
        if (!this.calculationParams()) return false;
        this.$refs.input.show();
      },
      saveSetting(name) {
        if (name.length < 1 || name.length > 10) {
          this.$message.warning("简报名称1~10个字符")
          return false
        }
        const _this = this;
        let params = this.calculationParams();
        if (!params) return false;
        params.name = name;
        this.saveLoading = true
        storeSetup(params).then(res => {
          if (res.data.state) {
            _this.$message.success("保存成功")
          } else {
            _this.$message.error(res.data.error)
          }
          _this.saveLoading = false
        }).catch(res => {
          _this.$message.error("保存设定失败，服务错误")
        })
      },
      canGenerate() {
        const {dimensions, spans, compareType, plan_ids, plan_id, span, name, subtitle, theme_color, filter} = this;
        if (!plan_id) {
          return false;
        }
        if (name.trim().length < 1) {
          return false;
        }
        if (filter.media.length < 1) {
          return false;
        }
        switch (compareType) {
          case 'multi_plan':
            var ids = _.filter(plan_ids)
            if (_.size(ids) < 1) {
              return false;
            }
            if (_.size(span) !== 2) {
              return false;
            }
            return true;
            break;
          case 'multi_dimension':
            if (_.size(span) !== 2) {
              return false;
            }
            if (_.size(dimensions) < 2) {
              return false;
            }
            var alreadyDimensionName = ""
            for (let i = 0; i < dimensions.length; i++) {
              dimensions[i].name = dimensions[i].name.trim()
              dimensions[i].must = dimensions[i].must.trim()
              dimensions[i].not = dimensions[i].not.trim()
              if (dimensions[i].name.length < 1) {
                return false;
              }
              if (alreadyDimensionName === dimensions[i].name) {
                return false;
              }
              alreadyDimensionName = dimensions[i].name
              if (dimensions[i].must.length == 0 && dimensions[i].not.length == 0) {
                return false;
              }
              let must = dimensions[i].must.split(",")
              let not = dimensions[i].not.split(",")
              if (must.length > 20 || not.length > 20) {
                return false;
              }
            }
            return true;
            break;
          case 'multi_time':
            var alreadyTimeName = ""
            if (_.size(spans) < 2) {
              return false;
            }
            for (let j = 0; j < spans.length; j++) {
              spans[j].name = spans[j].name.trim()
              if (spans[j].name.length < 1) {
                return false;
              }
              if (alreadyTimeName == spans[j].name) {
                return false;
              }
              alreadyTimeName = spans[j].name
              if (!spans[j].range) {
                return false;
              }
            }
            return true;
            break;
        }
      },
      calculationParams() {
        const _this = this
        const {dimensions, spans, compareType, plan_ids, plan_id, span, name, subtitle, theme_color, filter} = this
        if (!plan_id) {
          this.$message.warning("请选择主要方案")
          return false
        }
        if (name.trim().length < 1) {
          this.$message.warning("请填写报告名称")
          return false
        }
        if (filter.media.length < 1) {
          this.$message.warning("请选择媒体类型")
          return false
        }
        var params = {
          cond: {
            report_name: name.trim(),
            type: compareType,
            plan_id: plan_id,
            subtitle: subtitle.trim(),
            theme_color: theme_color,
            media: filter.media,
            lang: filter.lang,
            field: filter.field,
            weibo_forward: filter.weibo_forward,
            weibo_usertype: filter.weibo_usertype,
            author: filter.author
          }
        }
        switch (compareType) {
          case 'multi_plan':
            var ids = _.filter(plan_ids)
            if (_.size(ids) < 1) {
              this.$message.warning("请选择竞品方案")
              return false
            }
            if (_.size(span) !== 2) {
              this.$message.warning("请选择分析时间")
              return false
            }
            params.cond['plan_ids'] = ids;
            params.cond['span'] = `${span[0]}~${span[1]}`
            return params;
            break;
          case 'multi_dimension':
            if (_.size(span) !== 2) {
              this.$message.warning("请选择分析时间")
              return false
            }
            if (_.size(dimensions) < 2) {
              this.$message.warning("比较维度不能少于两个")
              return false
            }
            var alreadyDimensionName = ""
            for (let i = 0; i < dimensions.length; i++) {
              dimensions[i].name = dimensions[i].name.trim()
              dimensions[i].must = dimensions[i].must.trim()
              dimensions[i].not = dimensions[i].not.trim()
              if (dimensions[i].name.length < 1) {
                _this.$message.warning("比较关键词组名称不能为空")
                return false
              }
              if (alreadyDimensionName === dimensions[i].name) {
                _this.$message.warning('比较关键词组名称"' + alreadyDimensionName + '"不能重名或为空')
                return false
              }
              alreadyDimensionName = dimensions[i].name
              if (dimensions[i].must.length == 0 && dimensions[i].not.length == 0) {
                _this.$message.warning('请填写或删除比较关键词"' + dimensions[i].name + '"(主关键词或排除词不能同时为空)')
                return false
              }
              let must = dimensions[i].must.split(",")
              let not = dimensions[i].not.split(",")
              if (must.length > 20 || not.length > 20) {
                _this.$message.warning(dimensions[i].name + '，关键词组个数英文逗号分割（长度不能超过20组）')
                return false
              }
            }
            params.cond['dimensions'] = dimensions;
            params.cond['span'] = `${span[0]}~${span[1]}`
            return params;
            break;
          case 'multi_time':
            var alreadyTimeName = ""
            var dates = []
            if (_.size(spans) < 2) {
              this.$message.warning("比较时间不能少于两个")
              return false
            }
            for (let j = 0; j < spans.length; j++) {
              spans[j].name = spans[j].name.trim()
              if (spans[j].name.length < 1) {
                _this.$message.warning("比较时间名称不能为空")
                return false
              }
              if (alreadyTimeName == spans[j].name) {
                _this.$message.warning('比较时间名称"' + alreadyTimeName + '"不能重名')
                return false
              }
              alreadyTimeName = spans[j].name
              if (!spans[j].range) {
                _this.$message.warning('请选择或删除比较时间"' + spans[j].name + '"(比较时间不能为空)')
                return false
              }
              // 格式化
              dates.push({
                name: spans[j].name,
                start: `${spans[j].range[0]}`,
                end: `${spans[j].range[1]}`
              })
            }
            params.cond['spans'] = dates;
            return params;
            break;
        }
      },
      dateRange(date) {
        return dateRangeDisabled(date)
      },
      handleCheckAllChange() {
        this.filter.media = this.filter.checkAll ? this.medias : []
        this.filter.isIndeterminate = false
      },
      checkboxChange() {
        let checkedCount = this.medias.length
        this.filter.checkAll = checkedCount === this.filter.media.length;
        this.filter.isIndeterminate = this.filter.media.length > 0 && this.filter.media.length < checkedCount;
      },
      calculationDefaultName(key, name) {
        let source = key == 'time' ? this.spans : this.dimensions
        let prefix = key == 'time' ? "时区" : "关键词组"
        var names = source.map(function (item) {
          return item.name
        })
        if (names.indexOf(name) != -1) {
          for (let i in this.numberMap) {
            let newName = prefix + this.numberMap[i];
            if (names.indexOf(newName) == -1) return newName;
          }
        }
        return name
      },
      addItem(name) {
        switch (name) {
          case 'time':
            if (this.spans.length >= 6) {
              this.$message.warning("最多只能添加6组比较时间")
              return false
            }
            var sI = this.spans.length
            var sName = this.calculationDefaultName('time', "时区" + this.numberMap[sI])
            this.spans = this.spans.concat([{name: sName, range: ""}])
            break;
          case 'word':
            if (this.dimensions.length >= 6) {
              this.$message.warning("最多只能添加6组维度比较")
              return false
            }
            var dI = this.dimensions.length
            var dName = this.calculationDefaultName('word', "维度" + this.numberMap[dI])
            this.dimensions = this.dimensions.concat([{name: dName, op: "and", must: "", not: ""}])
            break;
          case 'plan':
            if (_.size(this.plan_ids) >= 5) {
              this.$message.warning("最多只能添加5组竞品方案")
              return false
            }
            this.plan_ids = this.plan_ids.concat([''])
            break;
        }
      },
      removeItem(name, index) {
        switch (name) {
          case 'time':
            if (_.size(this.spans) === 1) {
              this.$message.warning('时区不能为空')
              return false
            }
            this.spans.splice(index, 1)
            break;
          case 'word':
            if (_.size(this.dimensions) === 1) {
              this.$message.warning('比较维度不能为空')
              return false
            }
            this.dimensions.splice(index, 1)
            break;
          case 'plan':
            if (_.size(this.plan_ids) === 1) {
              this.$message.warning('至少需要选择一个竞品方案')
              return false
            }
            this.plan_ids.splice(index, 1)
            break;
        }
      }
    }
  }
</script>
<style scoped>
.yqbj .form-box{background: none;padding: 0;}
.yqbj .mtlx_bq >>> .el-form-item__label{margin-top: 10px;}
.yqbj .button-group .el-button:hover,.yqbj .button-group .checked {background: #555DFE;border-color: #555DFE;color: #fff;}
.w-310{width:310px;}
.yqbj >>> .form-box:nth-child(1) .el-form-item.el-form-item--small{margin-bottom: 20px;}
/*高级筛选*/
.yqbj .gjsx-cont {background:#f9f9f9;border-radius: 6px;padding:10px 20px 20px;text-align: left;}
.yqbj .gjsx-cont >>> .el-form-item__label {text-align: left;padding-left: 0;}
</style>
